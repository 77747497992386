import { AppointmentDrawerData, useActiveShopId, useEmailSending, useSnackBars } from '@ev/eva-container-api';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { useExpandedActivity } from 'api/graphql/hooks/useActivityDetails';
import { useCurrentAgentId } from 'api/graphql/hooks/useCurrentAgent';
import { useListing } from 'api/graphql/hooks/useListing';
import { queryClient } from 'api/queryClient';
import { CreateAppointmentRequest, useCreateAppointment, useUpdateAppointment } from 'api/rest/hooks/useAppointment';
import { CreateOrEditAppointmentForm } from 'components/dashboard/CreateOrEditAppointmentForm';
import { EVDrawerContent } from 'components/general/EVDrawer/EVDrawerContent';
import IconMail from 'components/icons/mail.svg?react';
import IconMore from 'components/icons/more.svg?react';
import { separateContactsAndLeads } from 'page-components/dashboard/separateContactsAndLeads';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetAppointmentDefaultValues } from 'util/defaultValues/appointmentDefaultValues';
import { UNASSIGNED } from 'util/emptyDataUtils';
import { useTranslation } from 'util/i18next';
import { useCreateNewAppointmentSchema } from 'util/schemas/newAppointmentSchema';
import { useErrorSnackBar } from 'util/useErrorSnackBar';
import { useSelectedGoogleIntegration } from 'util/useSelectedGoogleIntegration';
import { z } from 'zod';

export interface CreateOrEditAppointmentProps {
  onClose: () => void;
  initialAppointmentData: AppointmentDrawerData;
}

export function CreateOrEditAppointment({ onClose, initialAppointmentData }: CreateOrEditAppointmentProps) {
  const { t } = useTranslation(['user']);
  const { createAppointment, isLoading: isCreateLoading } = useCreateAppointment();
  const { updateAppointment, isLoading: isUpdateLoading } = useUpdateAppointment();
  const { openSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();
  const currentAgentId = useCurrentAgentId();
  const activeShopId = useActiveShopId();
  const { hasActiveGoogleAccountIntegration } = useSelectedGoogleIntegration();
  const getAppointmentDefaultValues = useGetAppointmentDefaultValues();
  const [anchorElMore, setAnchorElMore] = useState<Element | null>(null);
  const { openSendEmail } = useEmailSending();
  const onOpenMoreMenu = (event: React.MouseEvent) => setAnchorElMore(event.currentTarget);
  const onCloseMoreMenu = () => setAnchorElMore(null);

  const createNewAppointmentSchema = useCreateNewAppointmentSchema();
  const methods = useForm<z.infer<typeof createNewAppointmentSchema>>({
    mode: 'onTouched',
    resolver: zodResolver(createNewAppointmentSchema),
    defaultValues: () =>
      getAppointmentDefaultValues({
        currentAgentId,
        activeShopId,
        initialAppointmentData,
      }),
  });

  const isCompletedOrCancelled = ['COMPLETED', 'CANCELLED'].includes(methods.formState.defaultValues?.status ?? '');
  const editId = initialAppointmentData.editId;
  const { activities } = useExpandedActivity(
    {
      where: { appointmentActivity: { appointment: { id: { _eq: editId } } } },
    },
    { enabled: !!editId },
  );
  const firstActivity = activities?.[0];
  const listing = useListing({ utag: firstActivity?.appointmentActivity?.appointment.propertyIds[0] ?? '' }).listing;
  const drawerTitle = (() => {
    if (isCompletedOrCancelled) {
      return t('user:dashboard.appointment.completed.drawerTitle');
    }
    if (editId) {
      return t('user:dashboard.appointment.edit.drawerTitle');
    }
    return t('user:dashboard.appointment.add.drawerTitle');
  })();

  const onViewReportEmailClick = () =>
    openSendEmail({
      to: [{ contactId: listing?.listingOwnerContactId ?? '' }],
      appointmentId: editId,
      template: 'VIEWING_REPORT',
      propertyUtags: firstActivity?.appointmentActivity?.appointment.propertyIds,
    });
  const onSave = (data: z.infer<typeof createNewAppointmentSchema>): void => {
    const payload = mapAppointmentData(data, activeShopId!);
    if (editId) {
      updateAppointment(
        { requestBody: { ...payload }, appointmentId: editId },
        {
          onSuccess: () => {
            openSnackBar(t('user:dashboard.updateActivity.appointment.success'), 'success');
            queryClient.invalidateQueries({ queryKey: ['agent'] });
            onClose();
          },
          onError: (error) => openErrorSnackBar(t('user:dashboard.updateActivity.appointment.error'), error),
        },
      );
    } else {
      createAppointment(
        { requestBody: payload },
        {
          onSuccess: () => {
            openSnackBar(t('user:dashboard.addActivity.appointment.success'), 'success');
            queryClient.invalidateQueries({ queryKey: ['agent'] });
            onClose();
          },
          onError: (error) => openErrorSnackBar(t('user:dashboard.addActivity.appointment.error'), error),
        },
      );
    }
  };

  if (methods.formState.isLoading) {
    return null;
  }

  const canSendViewingReport = Boolean(listing?.listingOwnerContactId) && listing?.assignedAgentId == currentAgentId;
  return (
    <EVDrawerContent
      title={drawerTitle}
      onClose={onClose}
      {...(!isCompletedOrCancelled
        ? {
            primaryAction: {
              primaryButtonLabel: t('user:dashboard.drawer.addActivity.saveButton'),
              callback: methods.handleSubmit(onSave),
              disabled: !hasActiveGoogleAccountIntegration,
            },
          }
        : {})}
      extraFooterItems={
        firstActivity?.appointmentActivity?.appointment.type === 'VIEWING' && (
          <Button onClick={onOpenMoreMenu} disabled={!canSendViewingReport}>
            <IconMore />
          </Button>
        )
      }
      isLoading={isCreateLoading || isUpdateLoading}
    >
      <FormProvider {...methods}>
        <CreateOrEditAppointmentForm />
      </FormProvider>
      <Menu
        open={Boolean(anchorElMore)}
        anchorEl={anchorElMore}
        onClose={onCloseMoreMenu}
        onClick={onCloseMoreMenu}
        id="more-menu"
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: -8 }}
      >
        <MenuItem onClick={onViewReportEmailClick}>
          <IconMail />
          <Typography variant="body3" sx={{ marginLeft: 0.5, fontWeight: 'normal' }}>
            {t('user:activity.table.row.expanded.viewingFeedback')}
          </Typography>
        </MenuItem>
      </Menu>
    </EVDrawerContent>
  );
}

export function mapAppointmentData(
  data: z.infer<ReturnType<typeof useCreateNewAppointmentSchema>>,
  shopId: string,
): CreateAppointmentRequest {
  const startTime = new Date(data.date);
  const endTime = new Date(data.date);
  startTime.setHours(data.startTime.getHours());
  startTime.setMinutes(data.startTime.getMinutes());
  endTime.setHours(data.endTime.getHours());
  endTime.setMinutes(data.endTime.getMinutes());

  const contactsAndLeads = separateContactsAndLeads(data.contactsAndLeads);

  return {
    shopId,
    agentId: data.agentId,
    teamId: data.teamId !== UNASSIGNED ? data.teamId : undefined,
    type: data.actionType,
    status: data.status,
    subject: data.subject,
    endDateTime: endTime.toISOString(),
    startDateTime: startTime.toISOString(),
    comment: data.comment,
    contactIds: contactsAndLeads.contactIds,
    leadIds: contactsAndLeads.leadIds,
    feedback: data.feedback,
    additionalAgentIds: data.additionalAgents?.map((agent) => agent.id),
    ...(data.actionType === 'VIEWING' && { feedback: data.feedback }),
    ...(data.properties?.length && { linkedPropertyIds: data.properties.map((p) => p.utag) }),
  };
}
