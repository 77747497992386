import { EmailData, EmailSendingContext, SendBulkEmailData } from '@ev/eva-container-api';
import { useCurrentAgentId } from 'api/graphql/hooks/useCurrentAgent';
import { useGoogleIntegrations } from 'api/graphql/hooks/useGoogleIntegrations';
import { SendBulkEmailDialog } from 'components/emails/SendBulkEmailDialog/SendBulkEmailDialog';
import { SendEmailPopover } from 'components/emails/SendEmailPopover/SendEmailPopover';
import { useActiveShopId } from 'components/state/ActiveShopProvider';
import { usePopover } from 'components/state/UIStateProvider';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useEmailUrlParams, useSendBulkEmailUrlParams } from 'util/drawerUrlParams/useEmailUrlParams';
import sentry from 'util/sentry';

export const EMAIL_POPOVER_ID = 'emailPopover';
export const EMAIL_BULK_DIALOG_ID = 'emailBulk';

export function EmailSendingProvider({ children }: { children: ReactNode }) {
  const currentAgentId = useCurrentAgentId();
  const { activeShopId } = useActiveShopId();

  const [emailData, setEmailData] = useState<EmailData>();
  const [bulkEmailKey, setBulkEmailKey] = useState(0);

  const [sendBulkEmailData, setSendBulkEmailData] = useState<SendBulkEmailData>();
  const { isOpen: isEmailPopoverOpen, setOpen: setEmailPopoverOpen } = usePopover(EMAIL_POPOVER_ID);
  const { isOpen: isEmailBulkOpen, setOpen: setEmailBulkOpen } = usePopover(EMAIL_BULK_DIALOG_ID);
  const isSendBulkEmailDialogOpen = !!sendBulkEmailData && isEmailBulkOpen;

  useEffect(() => {
    if (activeShopId) {
      setEmailPopoverOpen(false);
      setEmailData(undefined);
    }
  }, [activeShopId, setEmailPopoverOpen, setEmailData]);

  const { googleIntegrations, isLoading: isLoadingIntegrations } = useGoogleIntegrations(
    {
      where: {
        active: { _eq: true },
        _or: [{ agentId: { _eq: currentAgentId ?? '' } }, { shopId: { _eq: activeShopId ?? '' } }],
      },
      agent_id: currentAgentId ?? '',
      shop_id: activeShopId ?? '',
    },
    { enabled: !!activeShopId },
  );

  const possibleFromEmails = useMemo(() => googleIntegrations ?? [], [googleIntegrations]);
  const defaultEmail =
    possibleFromEmails.find((integration) => integration.isDefault.aggregate?.count === 1)?.email ??
    possibleFromEmails[0]?.email ??
    '';

  const openSendEmail = useCallback(
    (data: EmailData) => {
      const hasNonAgentRecipient = data.to.some((recipient) => !('agentId' in recipient));
      if (data.sharedLeadId && hasNonAgentRecipient) {
        sentry.logError('Lead sharing via email is only supported for agents');
      } else {
        setEmailData(data);
        setEmailPopoverOpen(true);
      }
    },
    [setEmailPopoverOpen],
  );

  const openSendBulkEmailDialog = useCallback(
    (data: SendBulkEmailData) => {
      setBulkEmailKey((key) => key + 1); // force remount if it is already open
      setSendBulkEmailData(data);
      setEmailBulkOpen(true);
    },
    [setSendBulkEmailData, setEmailBulkOpen],
  );

  const closeSendBulkEmailDialog = () => {
    setSendBulkEmailData(undefined);
    setEmailBulkOpen(false);

    if (sendBulkEmailData?.onClose) {
      try {
        sendBulkEmailData?.onClose();
      } catch {
        // Ignore potential errors from remote apps
      }
    }
    if (sendBulkEmailData?.redirectUrl) {
      location.href = sendBulkEmailData?.redirectUrl;
    }
  };

  const closeEmailPopover = () => {
    setEmailPopoverOpen(false);
    setEmailData(undefined);

    if (emailData?.onClose) {
      try {
        emailData?.onClose();
      } catch {
        // Ignore potential errors from remote apps
      }
    }
    if (emailData?.redirectUrl) {
      location.href = emailData?.redirectUrl;
    }
  };

  useEmailUrlParams({ onEmailParams: openSendEmail, enabled: !isLoadingIntegrations });
  useSendBulkEmailUrlParams({ onSendBulkEmailParams: openSendBulkEmailDialog, enabled: !isLoadingIntegrations });

  return (
    <EmailSendingContext.Provider value={{ openSendEmail: openSendEmail, openSendBulkEmail: openSendBulkEmailDialog }}>
      {children}

      {isSendBulkEmailDialogOpen && (
        <SendBulkEmailDialog
          key={bulkEmailKey}
          from={defaultEmail ?? ''}
          possibleFromEmails={possibleFromEmails ?? []}
          onClose={closeSendBulkEmailDialog}
          initialData={sendBulkEmailData}
        />
      )}

      {isEmailPopoverOpen && (
        <SendEmailPopover
          from={defaultEmail ?? ''}
          initialData={emailData!}
          possibleFromEmails={possibleFromEmails ?? []}
          onClose={closeEmailPopover}
        />
      )}
    </EmailSendingContext.Provider>
  );
}
