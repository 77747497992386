import { useListing } from 'api/graphql/hooks/useListing';
import { EVChip, EVChipProps } from 'components/general/Chips/EVChip/EVChip';
import { EVAvatar } from 'components/general/EVAvatar/EVAvatar';
import { PopperEntity, PopperEntityHeader } from 'components/general/Popper/Entity';
import { Popper } from 'components/general/Popper/Popper';
import { Price } from 'components/general/Price';
import { GoEVIdLink } from 'page-components/contact/EVIdWithLink/GoEVIdLink';
import React from 'react';
import { useTranslation } from 'util/i18next';
import { addressToSingleLineString, addressToStreetString } from 'util/summaries/addressSummary';

type PropertyTooltipChipProps = {
  chipProps?: EVChipProps;
  handleDelete?: () => void;
  propertyId: string;
};

export const PropertyTooltipChip: React.FC<PropertyTooltipChipProps> = ({
  chipProps,
  handleDelete,
  propertyId: utag,
}) => {
  const { t } = useTranslation(['enums']);

  const { listing, isLoading } = useListing({ utag });
  const { address, previewImage, askingPrice, monthlyTotalRent } = listing || {};

  return (
    <Popper
      isLoading={isLoading}
      placement={'top-start'}
      anchor={
        <EVChip
          onDelete={handleDelete}
          label={addressToStreetString(address, listing?.fileAs)}
          avatar={previewImage ? <EVAvatar size="s" pictureSrc={previewImage} /> : undefined}
          {...chipProps}
        />
      }
    >
      <PopperEntity
        header={
          <PopperEntityHeader
            label={<GoEVIdLink goPath={`${listing?.type === 'SALE' ? 'sales' : 'lettings'}/${utag}`} label={utag} />}
            avatar={previewImage ? <EVAvatar isSquare size="l" pictureSrc={previewImage} /> : undefined}
            subLabel={addressToSingleLineString(t)(address)}
          />
        }
        body={<Price price={listing?.type === 'SALE' ? askingPrice : monthlyTotalRent} currency={listing?.currency} />}
      />
    </Popper>
  );
};
