import { useJwtToken } from '@ev/eva-container-api';
import {
  AgentWithShopMembership,
  DashboardActivity,
  EmailIntegrationScopeEnum,
  EmailSignature,
  EmailTemplate,
  ExpandedActivityListItem,
  LeadListEntry,
  LicensePartner,
  Shop,
  ShopInfo,
  Team,
  UserRoleEnum,
} from 'api/graphql/generated/graphql';
import { useAgentsWithAllMemberships } from 'api/graphql/hooks/useAgentsWithAllMemberships';
import { useCurrentAgentId } from 'api/graphql/hooks/useCurrentAgent';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useMemo } from 'react';
import { TEAM_ROLES, parseGlobalRolesFromJwtToken, parseRolesFromJwtToken } from 'util/keycloak/roles';

type ShopWithShopGroup = ShopInfo | LeadListEntry['shop'];

type ShopWithShopGroupAndTeams = {
  teams: { id: string }[];
} & ShopWithShopGroup;

export type LeadWithAssignment = {
  agentId?: string | null;
  createdBy: string;
  id: string;
  shop: ShopWithShopGroup;
  teamId?: string | null;
};

type TeamWithShop = { id: string; shop: ShopWithShopGroup };

export function usePermissions() {
  const { jwtToken } = useJwtToken();
  const currentAgentId = useCurrentAgentId();
  const roles = useMemo(() => parseRolesFromJwtToken(jwtToken), [jwtToken]);
  const globalRoles = useMemo(() => parseGlobalRolesFromJwtToken(jwtToken), [jwtToken]);

  return useMemo(() => {
    const { shopRoles, teamRoles } = roles;
    const isAdmin = globalRoles.includes('ADMIN');
    const isReadonlyAdmin = globalRoles.includes('READONLY_ADMIN');

    const getShopAndTeamRolesForShopId = (shopId: string): UserRoleEnum[] => [
      ...(shopRoles[shopId] ? shopRoles[shopId]! : []),
      ...Object.values(teamRoles)
        .filter(({ shopId: teamsShopId }) => shopId === teamsShopId)
        .map(({ role }) => role),
    ];

    const allRolesInShopGroup = (shop: ShopWithShopGroup): Set<UserRoleEnum> => {
      if (!shop?.shopGroup) {
        return new Set(shop ? getShopAndTeamRolesForShopId(shop.id) : []);
      }

      return new Set(shop.shopGroup.shops.map((s) => s.id).flatMap(getShopAndTeamRolesForShopId));
    };

    const hasRoleInTeam = (teamId: string | undefined | null, ...requiredRoles: UserRoleEnum[]): boolean => {
      if (!teamId || !(teamId in teamRoles)) {
        return false;
      }

      return requiredRoles?.includes(teamRoles[teamId]!.role);
    };

    const allRolesInShop = (shop: ShopWithShopGroup): Set<UserRoleEnum> =>
      new Set(shop ? getShopAndTeamRolesForShopId(shop.id) : []);

    const hasRoleInShopGroup = (shop: ShopWithShopGroup, ...requiredRoles: UserRoleEnum[]) => {
      const allRolesInShop = allRolesInShopGroup(shop);
      return requiredRoles.some((requiredRole) => allRolesInShop.has(requiredRole));
    };

    const hasRoleInShop = (shop: ShopWithShopGroup, ...requiredRoles: UserRoleEnum[]) => {
      const roles = allRolesInShop(shop);
      return requiredRoles.some((requiredRole) => roles.has(requiredRole));
    };

    const isShopMember = (shop: ShopWithShopGroup) => {
      return shop ? allRolesInShop(shop).size > 0 : false;
    };

    const isShopGroupMemberExcluding = (shop: ShopWithShopGroup, ...excludedRoles: UserRoleEnum[]) => {
      const rolesInShop = allRolesInShopGroup(shop);
      for (const excludedRole of excludedRoles) {
        rolesInShop.delete(excludedRole);
      }
      return rolesInShop.size > 0;
    };

    const isShopMemberExcluding = (shop: ShopWithShopGroup, ...excludedRoles: UserRoleEnum[]) => {
      const rolesInShop = allRolesInShop(shop);
      for (const excludedRole of excludedRoles) {
        rolesInShop.delete(excludedRole);
      }
      return rolesInShop.size > 0;
    };

    const check = {
      canSeeStatisticsForShop(shop: Shop) {
        return isAdmin || isReadonlyAdmin || hasRoleInShop(shop, 'SHOP_MANAGER');
      },
      canSeeStatisticsTeamsTab(shop: Shop) {
        return (
          isAdmin ||
          isReadonlyAdmin ||
          check.canSeeStatisticsForShop(shop) ||
          hasRoleInShop(shop, 'TEAM_LEAD', 'TEAM_LEAD_LIGHT', 'TEAM_ASSISTANT', 'TEAM_ASSISTANT_LIGHT')
        );
      },
      canSeeStatisticsForTeam(shop: Shop, team: Team) {
        return (
          isAdmin ||
          isReadonlyAdmin ||
          check.canSeeStatisticsForShop(shop) ||
          hasRoleInTeam(team.id, 'TEAM_LEAD', 'TEAM_LEAD_LIGHT', 'TEAM_ASSISTANT', 'TEAM_ASSISTANT_LIGHT')
        );
      },

      canSeeAllShops() {
        return isAdmin || isReadonlyAdmin;
      },
      canAccessBlockedShops() {
        return isAdmin || isReadonlyAdmin;
      },
      canSeeAdminUI() {
        return isAdmin || isReadonlyAdmin;
      },
      canEditLead(lead: LeadWithAssignment) {
        const isAssigned = currentAgentId && lead.agentId === currentAgentId;
        const isCreator = currentAgentId && lead.createdBy === currentAgentId;
        const isShopMember = isShopGroupMemberExcluding(lead.shop, 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER');
        return (
          isAdmin ||
          (isAssigned && isShopMember) ||
          (isCreator && hasRoleInShopGroup(lead.shop, 'CALL_CENTER_AGENT')) ||
          hasRoleInTeam(lead.teamId, 'TEAM_LEAD', 'TEAM_ASSISTANT', 'TEAM_LEAD_LIGHT', 'TEAM_ASSISTANT_LIGHT') ||
          hasRoleInShopGroup(lead.shop, 'SHOP_MANAGER', 'LEAD_MANAGER')
        );
      },
      canAssignLead(lead: LeadWithAssignment) {
        const isAssigned = currentAgentId && lead.agentId === currentAgentId;
        const isShopMemberWithHigherRoleThanAgentLight = isShopGroupMemberExcluding(
          lead.shop,
          'COMPLIANCE_MANAGER',
          'CONTENT_MANAGER',
          'AGENT_LIGHT',
        );
        return (
          isAdmin ||
          (isAssigned && isShopMemberWithHigherRoleThanAgentLight) ||
          hasRoleInTeam(lead.teamId, 'TEAM_LEAD', 'TEAM_ASSISTANT', 'TEAM_LEAD_LIGHT', 'TEAM_ASSISTANT_LIGHT') ||
          hasRoleInShopGroup(lead.shop, 'SHOP_MANAGER', 'LEAD_MANAGER', 'CALL_CENTER_AGENT')
        );
      },
      canReferLead(lead: LeadWithAssignment) {
        return check.canEditLead(lead);
      },
      canCreateLeadWithoutTeamAssignment(shop: ShopWithShopGroup) {
        return isAdmin || hasRoleInShop(shop, 'SHOP_MANAGER', 'LEAD_MANAGER');
      },
      canCreateLeadWithoutAgentAssignment(shop: ShopWithShopGroup, teamId?: string) {
        return (
          isAdmin ||
          hasRoleInShop(shop, 'SHOP_MANAGER', 'LEAD_MANAGER') ||
          hasRoleInTeam(teamId, 'TEAM_LEAD', 'TEAM_ASSISTANT', 'TEAM_LEAD_LIGHT', 'TEAM_ASSISTANT_LIGHT')
        );
      },
      canAssignLeadToTeamInGo3(shop: ShopWithShopGroup) {
        // Only available on lvl 1 shops, they don't have shop groups
        return isAdmin || hasRoleInShop(shop, 'SHOP_MANAGER', 'LEAD_MANAGER', 'CALL_CENTER_AGENT');
      },
      canAssignLeadToAgentInGo3(lead: LeadWithAssignment) {
        // Only available on lvl 1 shops, they don't have shop groups
        return (
          isAdmin ||
          hasRoleInTeam(lead.teamId, 'TEAM_LEAD', 'TEAM_ASSISTANT', 'TEAM_LEAD_LIGHT', 'TEAM_ASSISTANT_LIGHT') ||
          hasRoleInShop(lead.shop, 'SHOP_MANAGER', 'LEAD_MANAGER', 'CALL_CENTER_AGENT')
        );
      },
      canCreateShopTemplate(shop: Shop) {
        return (
          isAdmin ||
          hasRoleInShop(shop, 'SHOP_MANAGER', 'LEAD_MANAGER') ||
          shop.teams.some((team) => hasRoleInTeam(team.id, 'TEAM_ASSISTANT', 'TEAM_ASSISTANT_LIGHT'))
        );
      },
      canCreatePersonalTemplate(shop: Shop) {
        return isAdmin || isShopMemberExcluding(shop, 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER');
      },
      canEditTemplate(template: EmailTemplate, shop: Shop) {
        if (isAdmin) {
          return true;
        }

        if (template.type === 'AUTO') {
          return (
            isAdmin ||
            hasRoleInShop(shop, 'SHOP_MANAGER') ||
            shop.teams.some((team) => hasRoleInTeam(team.id, 'TEAM_ASSISTANT', 'TEAM_ASSISTANT_LIGHT'))
          );
        }
        switch (template.scope) {
          case 'PERSONAL':
            return template.agentId == currentAgentId;
          case 'TEAM':
            return (
              isAdmin ||
              hasRoleInShop(shop, 'SHOP_MANAGER', 'LEAD_MANAGER') ||
              hasRoleInTeam(template.teamId, 'TEAM_LEAD', 'TEAM_ASSISTANT', 'TEAM_LEAD_LIGHT', 'TEAM_ASSISTANT_LIGHT')
            );
          case 'SHOP':
            return template.shopId
              ? check.canCreateShopTemplate(shop) ||
                  shop.teams.some((team) => hasRoleInTeam(team.id, 'TEAM_ASSISTANT', 'TEAM_ASSISTANT_LIGHT'))
              : false;
          default:
            return false;
        }
      },

      canViewListingOwner(shop: ShopWithShopGroup, teamId: string | undefined | null) {
        // Only 'Shop Manager' 'Team Leads' 'Team Assistant' 'Agents' 'Team Lead Light if Property is within Team', 'Agent Light if Listing Agents' should see owner listed
        const hasShopLevelAccess = hasRoleInShopGroup(shop, 'SHOP_MANAGER', 'TEAM_LEAD', 'TEAM_ASSISTANT', 'AGENT');

        return isAdmin || hasShopLevelAccess || hasRoleInTeam(teamId, 'TEAM_LEAD_LIGHT');
      },

      canCreateTeamTemplate(shop: ShopWithShopGroup, team: Team) {
        return (
          isAdmin ||
          hasRoleInShop(shop, 'SHOP_MANAGER', 'LEAD_MANAGER') ||
          hasRoleInTeam(team.id, 'TEAM_LEAD', 'TEAM_ASSISTANT', 'TEAM_LEAD_LIGHT', 'TEAM_ASSISTANT_LIGHT')
        );
      },

      canAddUserToShop(shop: ShopWithShopGroup) {
        return isAdmin || hasRoleInShop(shop, 'SHOP_MANAGER');
      },
      canAddUserToTeam(team: TeamWithShop) {
        return !!check.getTeamRolesThatCanBeGranted(team).length;
      },
      getTeamRolesThatCanBeGranted(team: TeamWithShop) {
        if (isAdmin || hasRoleInShop(team.shop, 'SHOP_MANAGER') || hasRoleInTeam(team.id, 'TEAM_ASSISTANT')) {
          return TEAM_ROLES;
        }
        if (hasRoleInTeam(team.id, 'TEAM_ASSISTANT_LIGHT')) {
          return ['AGENT_LIGHT', 'TEAM_ASSISTANT_LIGHT', 'TEAM_LEAD_LIGHT'] satisfies UserRoleEnum[];
        }
        return [];
      },
      canAddUserToAnyShop() {
        return isAdmin;
      },
      canAddTeamToShop() {
        return isAdmin;
      },
      canEditTeamInShop() {
        return isAdmin;
      },
      canInactivateTeamInShop() {
        return isAdmin;
      },
      canCreateOrDeleteShopEmailIntegration(shop: ShopWithShopGroup) {
        return isAdmin || hasRoleInShop(shop, 'SHOP_MANAGER', 'LEAD_MANAGER');
      },
      canCreateOrDeletePersonalEmailIntegration(shop: ShopWithShopGroup) {
        return isAdmin || isShopMemberExcluding(shop, 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER');
      },
      canRemoveUserFromShop(shop: ShopWithShopGroup) {
        return check.canAddUserToShop(shop);
      },

      canAddAdmin() {
        return isAdmin;
      },
      canRemoveAdmin() {
        return isAdmin;
      },
      canRerunFailedLeadImports() {
        return isAdmin;
      },
      canDeleteFailedLeadImports() {
        return isAdmin;
      },
      canReassignResponsibleAgents(shop: ShopWithShopGroup) {
        // Only available on lvl 1 shops, they don't have shop groups
        return (
          isAdmin ||
          hasRoleInShop(
            shop,
            'SHOP_MANAGER',
            'LEAD_MANAGER',
            'CALL_CENTER_AGENT',
            'TEAM_ASSISTANT',
            'TEAM_LEAD',
            'TEAM_LEAD_LIGHT',
            'TEAM_ASSISTANT_LIGHT',
          )
        );
      },
      canVisitShop(shop: ShopWithShopGroup) {
        return isAdmin || isReadonlyAdmin || isShopMember(shop);
      },
      canEditShopProfile(shop: ShopWithShopGroup) {
        return (
          isAdmin ||
          hasRoleInShop(
            shop,
            'SHOP_MANAGER',
            'LEAD_MANAGER',
            'TEAM_ASSISTANT',
            'TEAM_ASSISTANT_LIGHT',
            'CONTENT_MANAGER',
          )
        );
      },
      canAdjustConsentShopSettings(shop: ShopWithShopGroup) {
        return isAdmin || hasRoleInShopGroup(shop, 'SHOP_MANAGER');
      },
      canEditAgentProfile(profileAgent: AgentWithShopMembership) {
        const isShopManagerOfAgent =
          profileAgent.userShopAssignments.some((assignment) =>
            hasRoleInShop(assignment.shop, 'SHOP_MANAGER', 'CONTENT_MANAGER'),
          ) ||
          profileAgent.userTeamAssignments.some((assignment) =>
            hasRoleInShop(assignment.team.shop, 'SHOP_MANAGER', 'CONTENT_MANAGER'),
          );

        const isTeamLeadOfAgent = profileAgent.userTeamAssignments.some((assignment) =>
          hasRoleInTeam(assignment.team.id, 'TEAM_ASSISTANT', 'TEAM_ASSISTANT_LIGHT'),
        );

        return isAdmin || profileAgent.id === currentAgentId || isShopManagerOfAgent || isTeamLeadOfAgent;
      },

      canCreateContact(shop: ShopWithShopGroupAndTeams) {
        return (
          isAdmin ||
          hasRoleInShop(
            shop,
            'SHOP_MANAGER',
            'LEAD_MANAGER',
            'CALL_CENTER_AGENT',
            'TEAM_LEAD',
            'TEAM_ASSISTANT',
            'AGENT',
            'AGENT_LIGHT',
          )
        );
      },

      canCreateNonServiceContact(shop: Shop) {
        return (
          isAdmin ||
          hasRoleInShop(shop, 'SHOP_MANAGER', 'LEAD_MANAGER', 'CALL_CENTER_AGENT', 'TEAM_LEAD', 'TEAM_ASSISTANT')
        );
      },

      showAgentUI(shop: Shop) {
        return (
          !isAdmin &&
          !hasRoleInShop(shop, 'SHOP_MANAGER', 'LEAD_MANAGER', 'CALL_CENTER_AGENT', 'COMPLIANCE_MANAGER') &&
          hasRoleInShop(shop, 'AGENT')
        );
      },

      canSeeLeadsAndContactsFromOtherAgents(shop: Shop) {
        return (
          isAdmin ||
          isReadonlyAdmin ||
          hasRoleInShop(
            shop,
            'SHOP_MANAGER',
            'LEAD_MANAGER',
            'CALL_CENTER_AGENT',
            'COMPLIANCE_MANAGER',
            'TEAM_LEAD',
            'TEAM_LEAD_LIGHT',
            'TEAM_ASSISTANT',
            'TEAM_ASSISTANT_LIGHT',
            'AGENT',
          )
        );
      },

      canEditContact(shop: ShopWithShopGroup) {
        // Users can edit all contacts that they are allowed to
        // For AGENT_LIGHT role this means only contacts with leads that are assigned to them
        return isAdmin || isShopGroupMemberExcluding(shop, 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER');
      },

      canLinkContactWithGo3(shop: ShopWithShopGroup) {
        // Only available on lvl 1 shops, they don't have shop groups
        return isAdmin || isShopMemberExcluding(shop, 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER');
      },

      canLinkContactToMailThread(shop: ShopWithShopGroup) {
        return isAdmin || isShopGroupMemberExcluding(shop, 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER');
      },

      canReferLeadToTeam(shop: ShopWithShopGroup, teamId?: string) {
        //Team lead and team assistant can refer to any team in their shop
        //Team assistant light can refer only to their team
        return (
          isAdmin ||
          hasRoleInShopGroup(shop, 'SHOP_MANAGER', 'LEAD_MANAGER', 'TEAM_LEAD', 'TEAM_ASSISTANT') ||
          hasRoleInTeam(teamId, 'TEAM_ASSISTANT_LIGHT')
        );
      },

      canSendEmail(shop: ShopWithShopGroup) {
        return isAdmin || isShopMemberExcluding(shop, 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER');
      },

      canSeeInbox(shop: ShopWithShopGroup, scope?: EmailIntegrationScopeEnum) {
        return hasRoleInShop(shop, 'SHOP_MANAGER', 'LEAD_MANAGER') || scope === 'PERSONAL';
      },

      canManageCallingSettings(shop: ShopWithShopGroup) {
        return isAdmin || hasRoleInShop(shop, 'SHOP_MANAGER');
      },

      canManageCustomDemandFields(shop: ShopWithShopGroup) {
        return isAdmin || hasRoleInShop(shop, 'SHOP_MANAGER');
      },

      canCall(shop: ShopWithShopGroup) {
        return hasRoleInShop(shop, 'CALL_CENTER_AGENT');
      },
      canCallInAnyShop() {
        let canCall = false;
        for (const shopId in shopRoles) {
          canCall = canCall || !!shopRoles[shopId]?.includes('CALL_CENTER_AGENT');
        }
        return canCall;
      },

      canCreateLead(shop: ShopWithShopGroup) {
        return isAdmin || isShopMemberExcluding(shop, 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER');
      },

      canChangeDefaultEmailSignature(shop: ShopWithShopGroup) {
        return isAdmin || isShopMemberExcluding(shop, 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER');
      },
      canCreatePersonalSignature(shop: ShopWithShopGroup) {
        return isAdmin || isShopMemberExcluding(shop, 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER');
      },
      canCreateShopSignature() {
        return isAdmin;
      },
      canCreateTeamSignature(shop: ShopWithShopGroupAndTeams, team?: Team) {
        return (
          isAdmin ||
          hasRoleInShop(shop, 'LEAD_MANAGER', 'SHOP_MANAGER') || // Note: should be the teams shop
          (team && hasRoleInTeam(team.id, 'TEAM_LEAD', 'TEAM_LEAD_LIGHT', 'TEAM_ASSISTANT', 'TEAM_ASSISTANT_LIGHT'))
        );
      },
      canEditSignature(shop: ShopWithShopGroup, signature: EmailSignature) {
        if (isAdmin) {
          return true;
        }

        switch (signature.scope) {
          case 'PERSONAL':
            return signature.agentId === currentAgentId;
          case 'TEAM':
            return (
              isAdmin ||
              hasRoleInShop(shop, 'SHOP_MANAGER', 'LEAD_MANAGER') ||
              hasRoleInTeam(signature.teamId, 'TEAM_LEAD', 'TEAM_LEAD_LIGHT', 'TEAM_ASSISTANT', 'TEAM_ASSISTANT_LIGHT')
            );
          case 'SHOP':
            return (
              isAdmin ||
              hasRoleInShop(shop, 'SHOP_MANAGER', 'LEAD_MANAGER') ||
              hasRoleInTeam(signature.teamId, 'TEAM_ASSISTANT')
            );
          default:
            return false;
        }
      },
      canDeleteSignature(shop: ShopWithShopGroup, signature: EmailSignature) {
        if (signature.scope === 'SHOP') {
          return isAdmin;
        }
        return isAdmin || check.canEditSignature(shop, signature);
      },
      canChangeShopSettings(shop: ShopWithShopGroup) {
        return isAdmin || hasRoleInShop(shop, 'SHOP_MANAGER');
      },
      canDeleteContacts(shop: ShopWithShopGroup) {
        return isAdmin || hasRoleInShopGroup(shop, 'SHOP_MANAGER', 'LEAD_MANAGER');
      },
      canSeeDuplicateContacts(shop: ShopWithShopGroup) {
        return (
          isAdmin ||
          isReadonlyAdmin ||
          hasRoleInShopGroup(shop, 'SHOP_MANAGER', 'LEAD_MANAGER', 'TEAM_ASSISTANT', 'TEAM_LEAD')
        );
      },
      canSelectReferralAgentDirectly(shop: ShopWithShopGroupAndTeams) {
        return isAdmin || hasRoleInShopGroup(shop, 'SHOP_MANAGER', 'LEAD_MANAGER', 'TEAM_LEAD', 'TEAM_ASSISTANT');
      },
      canCreateKycCheck(shop: ShopWithShopGroupAndTeams) {
        return (
          isAdmin || hasRoleInShopGroup(shop, 'SHOP_MANAGER', 'LEAD_MANAGER', 'TEAM_LEAD', 'TEAM_ASSISTANT', 'AGENT')
        );
      },
      canViewKycCheck(shop: ShopWithShopGroupAndTeams) {
        return (
          isAdmin ||
          isReadonlyAdmin ||
          hasRoleInShopGroup(shop, 'SHOP_MANAGER', 'LEAD_MANAGER', 'TEAM_LEAD', 'TEAM_ASSISTANT', 'AGENT')
        );
      },
      canSeeLiPaPage(licensePartner: LicensePartner) {
        return (
          isAdmin ||
          licensePartner.shops.some(({ id: shopId }) =>
            hasRoleInShop({ id: shopId }, 'SHOP_MANAGER', 'CONTENT_MANAGER'),
          )
        );
      },

      canSeeLeadHubMainPages(shop: ShopWithShopGroup) {
        return isAdmin || isReadonlyAdmin || isShopGroupMemberExcluding(shop, 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER');
      },

      canSeeKYCPages(shop: ShopWithShopGroup) {
        return isAdmin || isReadonlyAdmin || isShopGroupMemberExcluding(shop, 'CONTENT_MANAGER');
      },

      canEditAllTeamDocuments(shop: ShopWithShopGroup) {
        return isAdmin || hasRoleInShop(shop, 'SHOP_MANAGER', 'LEAD_MANAGER');
      },
      canUseLevelOneFeatures(shop: ShopWithShopGroup) {
        // Only available on lvl 1 shops, they don't have shop groups
        return isAdmin || isReadonlyAdmin || isShopMemberExcluding(shop, 'AGENT', 'AGENT_LIGHT');
      },
      canCreateShopGroups() {
        return isAdmin;
      },
      canEditShopGroups() {
        return isAdmin;
      },
      canChangeResponsibleAgentAndTeam(shop: ShopWithShopGroup) {
        return isAdmin || hasRoleInShopGroup(shop, 'SHOP_MANAGER', 'TEAM_ASSISTANT', 'TEAM_LEAD');
      },
      canUpdateAgentBetaTester() {
        return isAdmin;
      },
      canUpdateAgentEnergyAdvisory(shop: ShopWithShopGroup, teamIds?: string[]) {
        return (
          isAdmin ||
          hasRoleInShop(shop, 'SHOP_MANAGER') ||
          teamIds?.some((teamId) => hasRoleInTeam(teamId, 'TEAM_ASSISTANT', 'TEAM_LEAD'))
        );
      },
      canUpdateShopEnergyAdvisory() {
        return isAdmin;
      },
      canCreateConsent() {
        return !isReadonlyAdmin;
      },
      canSetRevocationDateConsent(shop: Shop) {
        return (
          isAdmin ||
          hasRoleInShop(shop, 'LEAD_MANAGER', 'SHOP_MANAGER', 'CALL_CENTER_AGENT') ||
          shop.teams.some((team) => hasRoleInTeam(team.id, 'TEAM_ASSISTANT', 'TEAM_LEAD'))
        );
      },
      canSeeInProgressRejectedReferrals(shop: Shop) {
        return isAdmin || isShopMemberExcluding(shop, 'AGENT', 'AGENT_LIGHT');
      },
      canLinkActivityToLeads(shop: Shop) {
        return isAdmin || isShopMemberExcluding(shop, 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER');
      },
      canFilterForAllAgents(shop: Shop) {
        return (
          isAdmin ||
          isReadonlyAdmin ||
          hasRoleInShopGroup(
            shop,
            'AGENT',
            'CALL_CENTER_AGENT',
            'LEAD_MANAGER',
            'SHOP_MANAGER',
            'TEAM_ASSISTANT',
            'TEAM_ASSISTANT_LIGHT',
            'TEAM_LEAD',
            'TEAM_LEAD_LIGHT',
          )
        );
      },
      canChangeShopAssignmentInShopGroup(shop: Shop) {
        return isAdmin || hasRoleInShopGroup(shop, 'LEAD_MANAGER', 'SHOP_MANAGER', 'TEAM_LEAD', 'TEAM_ASSISTANT');
      },
      canViewAgentProfile(shop: Shop) {
        return isAdmin || hasRoleInShop(shop, 'SHOP_MANAGER', 'TEAM_ASSISTANT', 'TEAM_ASSISTANT_LIGHT');
      },
      canEditActivity(
        shop: ShopWithShopGroup,
        activity: ExpandedActivityListItem | DashboardActivity,
        assignedAgentWithMembership: AgentWithShopMembership | undefined,
      ) {
        if (activity.category !== 'APPOINTMENT' && activity.category !== 'TASK') {
          return false;
        }

        const teamMembershipsOfAgentAssignedToActivity = assignedAgentWithMembership?.userTeamAssignments.filter(
          ({ team: { shop: shopOfAssignment } }) =>
            shopOfAssignment.id === shop.id || shop.shopGroup?.shops.some((s) => s.id === shopOfAssignment.id),
        );

        const entity = activity.appointmentActivity?.appointment || activity.taskActivity?.task;
        const assignedAgentId = entity?.agentId;
        const createdBy = entity?.createdBy;

        const isActivityAssignedToCurrentAgent = assignedAgentId === currentAgentId;
        const isActivityOwnedByCurrentAgent = createdBy === currentAgentId;

        if (
          isActivityAssignedToCurrentAgent &&
          isShopGroupMemberExcluding(shop, 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER')
        ) {
          return true;
        }

        if (
          (isActivityAssignedToCurrentAgent || isActivityOwnedByCurrentAgent) &&
          isShopGroupMemberExcluding(shop, 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER', 'AGENT_LIGHT')
        ) {
          return true;
        }

        if (
          teamMembershipsOfAgentAssignedToActivity?.some((assignment) =>
            hasRoleInTeam(assignment.team.id, 'TEAM_ASSISTANT_LIGHT', 'TEAM_LEAD_LIGHT', 'TEAM_ASSISTANT', 'TEAM_LEAD'),
          )
        ) {
          return true;
        }

        if (hasRoleInShopGroup(shop, 'LEAD_MANAGER', 'SHOP_MANAGER')) {
          return true;
        }
        return isAdmin;
      },
      canBlockContacts(shop: Shop) {
        return (
          isAdmin ||
          hasRoleInShopGroup(shop, 'SHOP_MANAGER', 'TEAM_LEAD', 'TEAM_ASSISTANT') ||
          shop.teams.some((team) => hasRoleInTeam(team.id, 'TEAM_ASSISTANT', 'TEAM_LEAD'))
        );
      },

      canSeeShopExport(shop: ShopWithShopGroup) {
        return isAdmin || isReadonlyAdmin || hasRoleInShop(shop, 'SHOP_MANAGER', 'TEAM_LEAD', 'TEAM_ASSISTANT');
      },
      canAddDirectlyToNewsletter(shop: Shop) {
        return isAdmin || isShopMemberExcluding(shop, 'AGENT_LIGHT', 'COMPLIANCE_MANAGER', 'CONTENT_MANAGER');
      },
    };

    return check;
  }, [currentAgentId, globalRoles, roles]);
}

export function useCanEditActivity(activity: ExpandedActivityListItem | DashboardActivity | undefined) {
  const { activeShop } = useActiveShop();
  const { canEditActivity } = usePermissions();

  const assignedAgentId = activity?.appointmentActivity?.appointment.agentId || activity?.taskActivity?.task.agentId;
  const { agentsWithMembership, isInitialLoading: isLoadingMembership } = useAgentsWithAllMemberships(
    { where: { id: { _eq: assignedAgentId } } },
    { enabled: !!assignedAgentId },
  );
  const assignedAgentWithMembership = agentsWithMembership?.[0];

  const isLoading = isLoadingMembership;
  return useMemo(
    () => !!activity && (isLoading || canEditActivity(activeShop, activity, assignedAgentWithMembership)),
    [activity, isLoading, canEditActivity, activeShop, assignedAgentWithMembership],
  );
}
