import {
  ActivityDrawerContext,
  AppointmentDrawerData,
  ShareExposeDrawerData,
  TaskDrawerData,
  useDrawer,
} from '@ev/eva-container-api';
import { EVDrawer } from 'components/general/EVDrawer/EVDrawer';
import { CreateOrEditAppointment } from 'page-components/dashboard/CreateOrEditAppointment';
import { CreateOrEditTask } from 'page-components/dashboard/CreateOrEditTask';
import { ShareExpose } from 'page-components/dashboard/ShareExpose';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useAppointmentDrawerUrlParams } from 'util/drawerUrlParams/useAppointmentDrawerUrlParams';
import { useShareExposeDrawerUrlParams } from 'util/drawerUrlParams/useShareActivityDrawerParams';
import { useTaskDrawerUrlParams } from 'util/drawerUrlParams/useTaskDrawerUrlParams';

export function ActivityDrawerProvider({ children }: PropsWithChildren) {
  const [isCreateNewTaskOpen, setCreateNewTaskOpen] = useDrawer('task');
  const [isCreateNewAppointmentOpen, setCreateNewAppointmentOpen] = useDrawer('appointment');
  const [isShareExposeOpen, setShareExposeOpen] = useDrawer('shareExpose');

  const [initialAppointmentData, setInitialAppointmentData] = useState<AppointmentDrawerData>();
  const [initialTaskData, setInitialTaskData] = useState<TaskDrawerData>();
  const [initialShareExposeData, setInitialShareExposeData] = useState<ShareExposeDrawerData>();

  const openAppointmentDrawer = useCallback(
    (data: AppointmentDrawerData = {}) => {
      setInitialAppointmentData(data);
      setCreateNewAppointmentOpen(true);
    },
    [setCreateNewAppointmentOpen],
  );

  const openTaskDrawer = useCallback(
    (data: TaskDrawerData = {}) => {
      setInitialTaskData(data);
      setCreateNewTaskOpen(true);
    },
    [setCreateNewTaskOpen],
  );

  const openShareExposeDrawer = useCallback(
    (data: ShareExposeDrawerData = {}) => {
      setInitialShareExposeData(data);
      setShareExposeOpen(true);
    },
    [setShareExposeOpen],
  );

  useTaskDrawerUrlParams({
    onTaskParams: openTaskDrawer,
  });

  useAppointmentDrawerUrlParams({
    onAppointmentParams: openAppointmentDrawer,
  });

  useShareExposeDrawerUrlParams({
    onShareExposeParams: openShareExposeDrawer,
  });

  const contextValue = useMemo(
    () => ({ openAppointmentDrawer, openTaskDrawer, openShareExposeDrawer }),
    [openAppointmentDrawer, openTaskDrawer, openShareExposeDrawer],
  );

  const handleCloseAppointmentDrawer = () => {
    setCreateNewAppointmentOpen(false);
    if (initialAppointmentData?.onClose) {
      try {
        initialAppointmentData?.onClose();
      } catch {
        // Ignore potential errors from remote apps
      }
    }
    if (initialAppointmentData?.redirectUrl) {
      location.href = initialAppointmentData.redirectUrl;
    }
  };

  const handleCloseTaskDrawer = () => {
    setCreateNewTaskOpen(false);
    if (initialTaskData?.onClose) {
      try {
        initialTaskData?.onClose();
      } catch {
        // Ignore potential errors from remote apps
      }
    }
    if (initialTaskData?.redirectUrl) {
      location.href = initialTaskData.redirectUrl;
    }
  };

  const handleCloseShareExposeDrawer = () => {
    setShareExposeOpen(false);
    if (initialShareExposeData?.onClose) {
      try {
        initialShareExposeData?.onClose();
      } catch {
        // Ignore potential errors from remote apps
      }
    }
  };

  return (
    <>
      <ActivityDrawerContext.Provider value={contextValue}>{children}</ActivityDrawerContext.Provider>
      <EVDrawer isOpen={isCreateNewTaskOpen} onClose={handleCloseTaskDrawer}>
        {initialTaskData && <CreateOrEditTask initialTaskData={initialTaskData} onClose={handleCloseTaskDrawer} />}
      </EVDrawer>
      <EVDrawer isOpen={isCreateNewAppointmentOpen} onClose={handleCloseAppointmentDrawer}>
        {initialAppointmentData && (
          <CreateOrEditAppointment
            initialAppointmentData={initialAppointmentData}
            onClose={handleCloseAppointmentDrawer}
          />
        )}
      </EVDrawer>
      <EVDrawer isOpen={isShareExposeOpen} onClose={handleCloseShareExposeDrawer}>
        {initialShareExposeData && (
          <ShareExpose initialShareExposeData={initialShareExposeData} onClose={handleCloseShareExposeDrawer} />
        )}
      </EVDrawer>
    </>
  );
}
