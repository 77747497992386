import { useEmailSending } from '@ev/eva-container-api';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useResolvedSignature } from 'api/rest/hooks/useEmail';
import { Attachment } from 'components/emails/Attachment';
import { PropertiesExposeWarning } from 'components/emails/PropertiesExposeWarning/PropertiesExposeWarning';
import { SignatureMenu } from 'components/emails/SignatureMenu';
import { TemplateSelector } from 'components/emails/TemplatesSelector/TemplateSelector';
import { RichTextEditor } from 'components/forms/RichTextEditor/RichTextEditor';
import { useSyncExposeFormStateToEditorState } from 'components/forms/RichTextEditor/blots/expose/useSyncExposeFormStateToEditorState';
import { RichTextContainer } from 'components/forms/RichTextEditor/styles';
import { FileUploadButton } from 'components/general/FileUploadButton/FileUploadButton';
import PaperClip from 'components/icons/paper_clip.svg?react';
import { PropertySearchField } from 'components/property/PropertySearch/PropertySearchField';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { emailBodyWithSignature, getTemplateContent, replaceExposePlaceholders } from 'util/email';
import { isSet } from 'util/filters';
import { useTranslation } from 'util/i18next';
import { ComposeBulkEmailFormData } from 'util/schemas/sendBulkEmailSchema';

export function ComposeBulkEmailTemplateForm({ disabled }: { disabled: boolean }) {
  const { t } = useTranslation(['communication', 'enums']);
  const { activeShopSettings, activeShop } = useActiveShop();
  const [warningClosed, setWarningClosed] = useState(false);
  const [warningClosedForPremiumExpose, setWarningClosedForPremiumExpose] = useState(false);
  const preferredShopLanguage = activeShopSettings?.defaultPreferredLanguage;

  const {
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<ComposeBulkEmailFormData>();

  const { openSendBulkEmail } = useEmailSending();

  const { signature, isLoading: isSignatureLoading } = useResolvedSignature({
    shopId: activeShop.id,
    emailSignatureId: watch('signatureId'),
  });
  const templateContent = getTemplateContent(watch('template'), preferredShopLanguage);

  const emailBody =
    templateContent && !isSignatureLoading
      ? emailBodyWithSignature(replaceExposePlaceholders(templateContent.body), signature)
      : '';

  const isExposeSending = watch('isExposeSending');
  const template = watch('template');
  const isPremiumExpose = template?.type === 'PREMIUM_EXPOSE_MAIL';

  const getExposeListProps = useCallback(
    (data: ComposeBulkEmailFormData) => ({
      properties: data.properties,
      language: templateContent?.language || 'EN',
      hidePrice: data.hidePrice,
    }),
    [templateContent],
  );
  useSyncExposeFormStateToEditorState<ComposeBulkEmailFormData>(getExposeListProps);

  const switchToExposeSending = () => {
    openSendBulkEmail({
      template: 'EXPOSE',
      to: getValues('to')
        .filter((r) => r.type === 'CONTACT' || r.intentType === 'BUY' || r.intentType === 'TO_RENT')
        .map((r) => (r.type === 'CONTACT' ? { contactId: r.id } : { leadId: r.id })),
      cc: getValues('cc').map((r) => (r.type === 'AGENT' ? { agentId: r.id } : { email: r.email })),
      bcc: getValues('bcc').map((r) => (r.type === 'AGENT' ? { agentId: r.id } : { email: r.email })),
      propertyUtags: getValues('properties')
        ?.map(({ utag }) => utag)
        .filter(isSet),
    });
  };
  const showSendExposeWarning = !isExposeSending && !!watch('properties')?.length && !warningClosed;
  const showPremiumExposeWarning =
    isExposeSending && isPremiumExpose && watch('properties')?.length > 1 && !warningClosedForPremiumExpose;

  return (
    <Stack gap={2}>
      <PropertySearchField
        required={isExposeSending}
        disabled={disabled}
        sx={{ width: '100%' }}
        label={t('communication:sendBulkEmailDialog.propertiesLabel')}
        onlyActiveProperties={isExposeSending}
        isEmail={true}
      />
      {showSendExposeWarning && (
        <PropertiesExposeWarning
          description={t('communication:email.sendExposeWarningDescription')}
          onAction={switchToExposeSending}
          onClose={() => setWarningClosed(true)}
        />
      )}
      {showPremiumExposeWarning && (
        <PropertiesExposeWarning
          description={t('communication:email.sendPremiumExposeWarningDescription')}
          onAction={switchToExposeSending}
          onClose={() => setWarningClosedForPremiumExpose(true)}
        />
      )}
      {/* We need to render a controller here otherwise the label does not move out the text field if the subjectOverride is set with setValue */}
      <Controller
        name="subjectOverride"
        control={control}
        render={({ field }) => (
          <TextField
            label={t('communication:emailForm.subject')}
            {...field}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Controller
                    name="template"
                    control={control}
                    render={({ field }) => (
                      <TemplateSelector
                        forExposeSending={isExposeSending}
                        selectedTemplateId={field.value?.id}
                        onTemplateSelected={(template) => {
                          setValue(
                            'subjectOverride',
                            getTemplateContent(template, preferredShopLanguage)?.subject || '',
                          );
                          field.onChange(template);
                        }}
                      />
                    )}
                  />
                </InputAdornment>
              ),
            }}
            error={!!errors.template}
            helperText={errors.template?.message}
          />
        )}
      />
      <RichTextContainer data-testid="richTextContainer">
        <RichTextEditor
          theme="snow"
          readOnly
          readOnlyTooltipLabel={t('communication:emailForm.readOnlyTooltipLabel')}
          value={emailBody}
          extraToolBarItems={
            <>
              <Controller
                name="signatureId"
                control={control}
                render={({ field }) => (
                  <SignatureMenu onSignatureSelected={({ emailSignatureId }) => field.onChange(emailSignatureId)} />
                )}
              />

              <Controller
                name="attachments"
                control={control}
                render={({ field }) => (
                  <FileUploadButton
                    allowMultipleSelection
                    icon={<PaperClip />}
                    byteSumOfAllUploads={field.value.reduce((acc, file) => acc + file.size, 0)}
                    onUpload={(files) => field.onChange([...field.value, ...files])}
                  />
                )}
              />
            </>
          }
        />
        <Controller
          name="attachments"
          control={control}
          render={({ field }) => (
            <Box>
              {field.value.map((file, index) => (
                <Attachment
                  key={`attachment-${index}`}
                  name={file.name}
                  size={file.size}
                  onRemove={() => field.onChange(field.value.filter((f) => f !== file))}
                />
              ))}
            </Box>
          )}
        />
      </RichTextContainer>
    </Stack>
  );
}
