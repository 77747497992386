import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IntentTypeEnum } from 'api/graphql/generated/graphql';
import { NumberFieldWithController } from 'components/forms/NumberField/NumberField';
import { EVAlert } from 'components/general/EVAlert/EVAlert';
import { EVDrawerCollapsible } from 'components/general/EVDrawer/EVDrawerCollapsible';
import { FormSeparatorIcon } from 'components/general/Form/FormSeparatorIcon';
import { FormStack } from 'components/general/Form/FormStack';
import PriceIcon from 'components/icons/money_notes.svg?react';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { SyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'util/i18next';
import { PropertyDetailsFormData } from 'util/schemas/propertyDetailsSchema';
import { useCurrencySymbol, useFormatNumber } from 'util/useFormatNumber';

const PriceSection = ({ intentType }: { intentType?: IntentTypeEnum }) => {
  const { t } = useTranslation(['lead', 'enums']);
  const { formatPriceRange } = useFormatNumber();

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useFormContext<PropertyDetailsFormData>();

  const { shopCurrency } = useActiveShop();
  const shopCurrencySymbol = useCurrencySymbol(shopCurrency);

  const currency = watch('search.currency');
  const currencySymbol = useCurrencySymbol(currency);
  const hasNonShopCurrency = currency && currency !== shopCurrency;

  const handleChangeToShopCurrency = (event: SyntheticEvent) => {
    event.stopPropagation();
    setValue('search.currency', shopCurrency);
    trigger([
      'search.priceMin',
      'search.priceMax',
      'search.monthlyNetRentMin',
      'search.monthlyNetRentMax',
      'search.monthlyTotalRentMin',
      'search.monthlyTotalRentMax',
    ]);
  };

  const isBuySearchDemand = intentType === 'BUY';

  const getPriceValues = () => {
    if (isBuySearchDemand)
      return formatPriceRange(...watch(['search.priceMin', 'search.priceMax']), {
        emptyDataString: '',
        currency,
      });
    const priceValues = [
      formatPriceRange(...watch(['search.monthlyNetRentMin', 'search.monthlyNetRentMax']), {
        emptyDataString: '',
        currency,
      }),
      formatPriceRange(...watch(['search.monthlyTotalRentMin', 'search.monthlyTotalRentMax']), {
        emptyDataString: '',
        currency,
      }),
    ].filter(Boolean);
    if (priceValues.length) {
      return priceValues.join(', ');
    }
    return undefined;
  };

  const priceValues = getPriceValues();

  return (
    <Box>
      {hasNonShopCurrency && <EVAlert severity="warning">{t('lead:currency.nonShopCurrency')}</EVAlert>}

      <EVDrawerCollapsible
        title={t('lead:form.searchDemand.priceHeader', { currency: currencySymbol })}
        icon={<PriceIcon />}
        collapsedValues={priceValues}
      >
        {hasNonShopCurrency && (
          <Button onClick={handleChangeToShopCurrency} variant="outlined" color="secondary" sx={{ marginLeft: 5 }}>
            {t('lead:currency.changeToShopCurrency', { currency: shopCurrencySymbol })}
          </Button>
        )}
        {isBuySearchDemand ? (
          <FormStack direction={'row'} gap={0.25}>
            <NumberFieldWithController
              label={t('lead:propertyEvaluateForm.details.priceRangeMin', { currency: currencySymbol })}
              error={!!errors.search?.priceMin}
              helperText={errors.search?.priceMin?.message}
              name="search.priceMin"
              allowNegative={false}
              control={control}
            />
            <FormSeparatorIcon />
            <NumberFieldWithController
              label={t('lead:propertyEvaluateForm.details.priceRangeMax', { currency: currencySymbol })}
              error={!!errors.search?.priceMax}
              helperText={errors.search?.priceMax?.message}
              name="search.priceMax"
              allowNegative={false}
              control={control}
            />
          </FormStack>
        ) : (
          <>
            <FormStack direction={'row'} gap={0.25}>
              <NumberFieldWithController
                label={t('lead:propertyEvaluateForm.details.monthlyNetRentMin', { currency: currencySymbol })}
                error={!!errors.search?.monthlyNetRentMin}
                helperText={errors.search?.monthlyNetRentMin?.message}
                name="search.monthlyNetRentMin"
                allowNegative={false}
                control={control}
              />
              <FormSeparatorIcon />
              <NumberFieldWithController
                label={t('lead:propertyEvaluateForm.details.monthlyNetRentMax', { currency: currencySymbol })}
                error={!!errors.search?.monthlyNetRentMax}
                helperText={errors.search?.monthlyNetRentMax?.message}
                name="search.monthlyNetRentMax"
                allowNegative={false}
                control={control}
              />
            </FormStack>

            <FormStack direction={'row'} gap={0.25}>
              <NumberFieldWithController
                label={t('lead:propertyEvaluateForm.details.monthlyTotalRentMin', { currency: currencySymbol })}
                error={!!errors.search?.monthlyTotalRentMin}
                helperText={errors.search?.monthlyTotalRentMin?.message}
                name="search.monthlyTotalRentMin"
                allowNegative={false}
                control={control}
              />
              <FormSeparatorIcon />
              <NumberFieldWithController
                label={t('lead:propertyEvaluateForm.details.monthlyTotalRentMax', { currency: currencySymbol })}
                error={!!errors.search?.monthlyTotalRentMax}
                helperText={errors.search?.monthlyTotalRentMax?.message}
                name="search.monthlyTotalRentMax"
                allowNegative={false}
                control={control}
              />
            </FormStack>
          </>
        )}
      </EVDrawerCollapsible>
    </Box>
  );
};

export default PriceSection;
